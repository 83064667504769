<template>
  <div>
    <download></download>
    <div class="page2 cl">
      <img
        class="top-banner"
        src="./../../assets/img/floorPage/page2topbanner.png"
        alt=""
      />
      <div class="big-name">
        <div class="animation">
          <img class="mt1" src="./../../assets/img/floorPage/mt1.png" alt="" />
          <img class="mt2" src="./../../assets/img/floorPage/mt2.png" alt="" />
          <img class="mt3" src="./../../assets/img/floorPage/mt3.png" alt="" />
          <img class="mt4" src="./../../assets/img/floorPage/mt4.png" alt="" />
          <img class="mt5" src="./../../assets/img/floorPage/mt5.png" alt="" />
          <img class="mt6" src="./../../assets/img/floorPage/mt6.png" alt="" />
        </div>
        <ul class="goods">
          <li class="item" v-for="(item, index) in goodList" :key="index">
            <img :src="item.imgPath" alt="" />
            <p class="good-name">{{ item.text }}</p>
            <div class="good-btn">免费领取</div>
          </li>
        </ul>
      </div>
      <div class="content">
        <img
          class="free-title"
          src="./../../assets/img/floorPage/pg2rementuijian.png"
        />
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img src="./../../assets/img/floorPage/001.png" alt="" />
              <p>大牌包袋</p>
            </div>
            <div class="swiper-slide">
              <img src="./../../assets/img/floorPage/002.png" alt="" />
              <p>大牌手表</p>
            </div>
            <div class="swiper-slide">
              <img src="./../../assets/img/floorPage/003.png" alt="" />
              <p>大牌豪车</p>
            </div>
          </div>
        </div>
        <img
          class="free-title"
          src="./../../assets/img/floorPage/pg2xinrenzhuanxiang.png"
        />
        <div class="welfare">
          <img src="../../assets/img/floorPage/xinrenfuli.png" alt="" />
        </div>
      </div>
      <div class="xiazai" @click="downloadApp">立即下载</div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import download from "./component/download.vue";
export default {
  components: { download },
  data() {
    return {
      goodList: [
        {
          imgPath: require("../../assets/img/floorPage/pg2good1.png"),
          text: "迪奥口红999"
        },
        {
          imgPath: require("../../assets/img/floorPage/pg2good2.png"),
          text: "古驰花之舞香水"
        },
        {
          imgPath: require("../../assets/img/floorPage/pg2good3.png"),
          text: "范思哲男士香水"
        },
        {
          imgPath: require("../../assets/img/floorPage/pg2good4.png"),
          text: "古驰男士淡香水"
        },
        {
          imgPath: require("../../assets/img/floorPage/pg2good5.png"),
          text: "迪奥花漾甜心"
        },
        {
          imgPath: require("../../assets/img/floorPage/pg2good6.png"),
          text: "蓝药丸面膜x2盒"
        }
      ]
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      autoplay: 2000,
      // slidesPerView: 3,
      spaceBetween: 20,
      // effect: "cube"
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      coverflow: {
        rotate: 30, //slide做3d旋转时Y轴的旋转角度。默认50。
        // stretch: -stretchNum, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
        depth: 80, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
        slideShadows: false //开启slide阴影。默认 true。
      }
    });
  },
  methods: {
    downloadApp() {
      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        window.location.href = "https://apk.yhaocang.com/yhsc_1007.apk";
      }
      if (isiOS) {
        window.location.href = "https://apps.apple.com/cn/app/id1549528607";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.page2 {
  background: url("../../assets/img/floorPage/page2topbgc.png") 0 0 no-repeat,
    #fffadb;
  background-size: 100%;
  // height: 2000px;
  padding-top: 27px;
  .top-banner {
    width: 696px;
    height: 374px;
    display: block;
    margin: auto;
  }
  .big-name {
    margin-top: 125px;
    height: 1265px;
    width: 750px;
    background: url("../../assets/img/floorPage/page2dapaimianfeipng.png") 0 0
      no-repeat;
    background-size: 100%;
    padding-top: 510px;
    position: relative;
    .animation {
      position: absolute;
      top: -125px;
      width: 750px;
      height: 500px;
      overflow: hidden;
      // position: relative;
      img {
        position: absolute;
      }
      .mt1 {
        width: 153px;
        height: 153px;
        animation: one 2s infinite;
        left: -75px;
      }
      .mt2 {
        width: 153px;
        height: 153px;
        animation: one 2s infinite;
        left: 139px;
        top: 44px;
      }
      .mt3 {
        width: 191px;
        height: 191px;
        animation: one 2.6s infinite;
        left: 295px;
        top: 79px;
      }
      .mt4 {
        width: 168px;
        height: 168px;
        animation: one 3s infinite;
        top: 252px;
        left: 378px;
      }
      .mt5 {
        width: 166px;
        height: 166px;
        animation: one 2.5s infinite;
        left: 520px;
        top: 0;
      }
      .mt6 {
        width: 163px;
        height: 163px;
        animation: one 3.5s infinite;
        left: 652px;
        top: 106px;
      }
    }
    .goods {
      width: 676px;
      height: 700px;
      margin: auto;

      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 215px;
        height: 342px;
        background: #ffffff;
        border-radius: 7px;
        img {
          width: 215px;
          height: 215px;
        }
        .good-name {
          font-size: 25px;
          font-weight: 500;
          color: #333333;
          line-height: 2.5;
          text-align: center;
        }
        .good-btn {
          width: 187px;
          height: 47px;
          background: linear-gradient(0deg, #fd994b 0%, #ef7311 100%);
          border-radius: 7px;
          line-height: 47px;
          margin: auto;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
  .content {
    .free-title {
      width: 517px;
      height: 36px;
      display: block;
      margin: auto;
      margin-top: 56px;
    }
    .swiper-wrapper {
      height: 350px;
      margin-top: 100px;
      .swiper-slide {
        width: 256px;
        height: 320px;
        border-radius: 20px;
        background-color: #fff;
        img {
          width: 218px;
          height: 218px;
          display: block;
          margin: auto;
          margin-top: 20px;
          overflow: hidden;
        }
        p {
          text-align: center;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
    .welfare {
      margin-top: 61px;
      img {
        width: 708px;
        height: 488px;
        display: block;
        margin: auto;
      }
    }
  }
  .xiazai {
    height: 117px;
    width: 750px;
    background: #f9e044;
    line-height: 117px;
    text-align: center;
    font-size: 43px;
    font-weight: bold;
    color: #333333;
    position: fixed;
    bottom: 0;
    z-index: 100;
  }
}
::after {
  content: "";
  height: 250px;
  // margin-top: 117px;
}

@keyframes one {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, -10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
</style>
